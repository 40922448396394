export default [
  {
    path: "/config/notifications",
    name: "notifications",
    component: () => import(/* webpackChunkName: "users" */ "../views/NotificationAccounts.vue"),
    meta: {
      title: "Notificações",
      layout: "config",
      guards: ["acl", "feature"],
      slug: "notification.view",
      featureSlug: "notification",
    },
  }
]
